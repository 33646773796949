import React from 'react'
import './options.scss'
import TimedInput from '../UI/TimedChangeInput'
import { FiCheck } from "react-icons/fi";
import MultiSelect from '../UI/MultiSelect';

const OptionBar = ({ setOpt, opt, categories }) => (
    <>
        <div className="option_inputs">
            <h2>Categorie</h2>
            <MultiSelect onChange={e => setOpt({ categories: e.join(',') })} value={opt.categories?.split(',').filter(a => a)} options={categories?.map(a => [a, a])} />
            {/* <select onChange={e => setOpt({ categories: e.target.value })} value={opt.categories}>
                {categories?.map(name => <option value={name} key={name}>{name}</option>)}
            </select> */}
        </div>
        <div className="option_inputs">
            <h2>Ville</h2>
            <TimedInput defaultValue={opt.city} placeholder="Saisir une ville..." onTimedChange={city => city && setOpt({ city })} />
        </div>
        <div className="option_inputs">
            <h2>Mode</h2>
            {["carousel", "pageview", "mono"]
                .map(a => <button key={a} className={opt.type === a ? 'current' : ""} onClick={() => opt.type !== a && setOpt({ type: a })}>{a}<FiCheck /></button>)}
        </div>
    </>
)


export default OptionBar
