import { useEffect, useState } from "react";
import { useDiffState } from '@oriun/hookster'
import Menu from "./Components/Menu/index.js";
import './App.scss'
import Snippet from "./Components/Snippet/";

const Timenjoy = window.TimenjoyJS
const TimenjoyJSInterface = window._TimenjoyJSInterface

function App() {
  const [opt, setOptions] = useDiffState(JSON.parse(localStorage.widgetOpt || null) || {
    type: "carousel", city: "Paris",
    distance: 50,
    topevents: "false",
    categories: "Spectacle",
    style: {
      mobileMargin: 20
    }
  })
  const [pop, setPop] = useState(false)
  const [categories, setC] = useState([])
  const [tnj, setTnj] = useState(null)

  useEffect(() => {
    if (opt.app_secret && !categories.length) TimenjoyJSInterface.init(opt.app_secret)
      .then(() => setC(TimenjoyJSInterface.categories.map(a => a.name[0] + a.name.slice(1).toLowerCase())))
      .catch(() => localStorage.removeItem('widgetOpt') || window.alert('Invalid API KEY ' + opt.app_secret) || window.location.reload())
  }, [opt, categories.length])

  useEffect(() => {
    if (opt.rememberme) localStorage.setItem('widgetOpt', JSON.stringify(opt))
    console.log(categories)
    if (!opt.app_secret || !categories.length) return
    if (!tnj) return setTnj(new Timenjoy(document.querySelector('#tnj'), { ...opt }))
    const options = tnj.getOptions()
    if (opt.city !== options.city || opt.categories !== options.categories || opt.type !== options.type) {
      if (opt.city !== options.city) {
        tnj.setCity(opt.city)
      }
      if (opt.categories !== options.categories) {
        tnj.setCategories(opt.categories)
      }
      if (opt.type !== options.type) {
        console.log('setType', opt.type)
        tnj.setType(opt.type)
      }
    } else {
      console.log('style')
      tnj.setStyle(opt.style)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opt, categories])


  useEffect(() => () => tnj && tnj.kill(), [])
  useEffect(() => document.querySelector('#app_secret_input')?.focus(), [])

  if (!opt.app_secret || !categories.length) return (
    <div className="salle_attente">
      <div>
        <h1 className='green'>Widget Timenjoy</h1>
        <p>Testez et personnalisez le widget pour profiter du meilleur de Timenjoy sur votre site web.</p>
        <h3>Entrez votre clé API</h3>
        <input type="text" id="app_secret_input" placeholder="API KEY" />
        <div>
          <input type="checkbox" id="remember_me" />
          <label>Se souvenir de ma session</label>
        </div>
        <button onClick={() => {
          const app_secret = document.querySelector('#app_secret_input')?.value
          const rememberme = document.querySelector('#remember_me')?.checked
          if (!app_secret) return // Do something if wrong entry
          setOptions({ app_secret, rememberme })
        }}>Continuer</button>
      </div>
    </div>
  )

  return (
    <>
      <div className="widget-page noscrollbar">
        <div id="tnj" />
        {/* <Snippet opt={opt}/> */}

        {/* <div className="switch">
          <div className="top-card">
          <h1>Découvrez les derniers événements</h1>
          <h3>Parcourez toutes les options et trouvez tous vos événements préférés</h3>
          <p>Taille, couleur, police...
          Personnalisez l'affichage, et intégrez parfaitement l'Iframe sur vos solutions</p>
          <button>Passer en mode page view</button>
          </div>
          <div id="tnj">
          </div>
          <div className="bottom-card">
          <h1>Timenjoy sans limite</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis dui fames dis in iaculis dictumst sagittis. Elementum a ac a dictumst sit sed a neque.</p>
          <div className="grid">
          <p>Accès au catalogue d'événéments</p>
          <p>Accès au catalogue d'événéments</p>
          <p>Accès au catalogue d'événéments</p>
          <p>Accès au catalogue d'événéments</p>
          <button>Découvrir toutes les options</button>
          </div>
          </div>
        </div> */}
      </div>
      <Menu opt={opt} setOpt={setOptions} pop={pop} setPop={setPop} categories={categories} />
      {pop && <Snippet opt={opt} setPop={setPop} />}
    </>
  );
}

export default App;
