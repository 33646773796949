import React, { useRef, useMemo, useState, forwardRef } from 'react'
import './search-input.scss'

const TimedChangeInput = ({ className, onChange, defaultValue, placeholder, onClick, onTimedChange, ms = 500, value: val }) => {
    const [value, setValue] = useState(defaultValue)
    const timedChange = useMemo(() => {
        const a = (function* gen() {
            let i = 0;
            let lastChange
            while (true) {
                let newValue = yield i
                if (newValue !== null) {
                    i += 1
                    lastChange = newValue
                } else {
                    if (i === 1) {
                        onTimedChange?.(lastChange)
                    }
                    i--
                }
            }
        })()
        a.next()
        return a
    }, [onTimedChange])
    const sureChange = ({ target: { value: newValue } }) => {
        setValue(newValue)
        if (onTimedChange) {
            timedChange.next(newValue)
            setTimeout(() => timedChange.next(null), ms)

        }
        onChange?.(newValue)
    }
    React.useEffect(() => setValue(val), [val])
    return (
            <input
                onChange={sureChange}
                value={value}
                className={'search-input ' + (className || '')}
                type="text"
                onClick={onClick}
                placeholder={placeholder} />
    )
}

export default TimedChangeInput