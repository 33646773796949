import React from 'react'
import './index.scss'
import { FiSliders, FiEdit2, FiTerminal, FiLogOut } from "react-icons/fi";
import OptionBar from '../Options'
import PersoBar from '../Personnalisation';
const Menu = ({ opt, setOpt, pop, setPop, categories }) => {
    const [page, setPage] = React.useState(null)
    const changePage = target => () => {
        if (page === target) setPage(null)
        else setPage(target)
    }
    React.useEffect(() => {
        if (page === null) return
        const listener = e => setPage(null)
        document.body.addEventListener('click', listener)
        return () => document.body.removeEventListener('click', listener)
    }, [page])
    return (
        <div className="menu" onClick={e => e.stopPropagation()}>
            <div className="main-menu">
                <img className="logo" width="48" height="48" alt="timenjoy logo" src="/logo192.png" />
                <FiSliders className={"icones " + (page === false ? 'active' : '')} onClick={changePage(false)} />
                <FiEdit2 className={"icones " + (page === true ? 'active' : '')} onClick={changePage(true)} />
                <FiTerminal className="icones" onClick={() => setPop(!pop)}/>
                <FiLogOut className="icones" onClick={() => {localStorage.clear(); window.location.reload(true)}}/>
            </div>
            {page !== null && (
                <div className="second-menu noscrollbar">
                    {page ? <PersoBar opt={opt} setOpt={setOpt} /> : <OptionBar opt={opt} setOpt={setOpt} categories={categories} />}
                </div>
            )}
        </div>
    )
}

export default Menu
