import React from 'react'
import './index.scss'

const src = `<script defer src="https://widget.timenjoy.fr/timenjoy-api.js"></script>`
const Snipett = ({ opt, setPop }) => {
    const div = `<div class="tnj-frame" data-app_secret="${opt.app_secret}" data-type="${opt.type}" data-city="${opt.city}" data-distance="50" data-categories="${opt.categories}" data-style="${JSON.stringify(opt.style).replace(/"/gm,"&quot;")}">\n</div>`
    const iframe = `<iframe src="https://widget.timenjoy.fr/?type=${opt.type}&app_secret=${opt.app_secret}&city=${opt.city}&distance=50&categories=${opt.categories}&style=${encodeURIComponent(JSON.stringify(opt.style))}" height="276"  style="border:none;"></iframe>`
    return (


        <div className="code-popup-overlay" onClick={() =>  setPop(false)}>
            <div className="code-popup" onClick={e => e.stopPropagation()}>
                <div className="title-line">
                    <h2>Comment ajouter le widget à votre page ? {/*<a>Envoyer à un développeur <img src="/img/paper-plane.svg"></img></a>*/}</h2>
                </div>
                <div className="code-flex">
                    <div className="code-rectangle">
                        <p>
                            {div}
                            <br />
                            {src}
                        </p>
                        <img className="img-kifaychiay" src="/img/copy-square.svg" onClick={() => { navigator.clipboard.writeText(div + src) }}></img>
                    </div>

                    <p>Pour utilisez votre widget, il vous suffit d’insérer le code ci-dessus dans votre page à l’endroit où vous voulez l’insérer. Si votre site ou CMS ne supporte pas l’insertion de script, vous pouvez utiliser le code ci-dessous à la place :</p>

                    <div className="code-rectangle">
                        <p>{iframe}</p>
                        <img src="/img/copy-square.svg" onClick={() => { navigator.clipboard.writeText(iframe) }}></img>
                    </div>
                </div>

                {/* <div className="code-instructions">
                    <button onClick={() => { navigator.clipboard.writeText(div + src) }}>Copier le code</button>
                </div> */}
            </div>
        </div >
    )
}

export default Snipett
