import React from 'react'
import './index.scss'
import ColorInput from '../UI/ColorInput'
import TimedChangeInput from '../UI/TimedChangeInput'
import { Fragment } from 'react/cjs/react.production.min'
const Params = {
    Général: {
        color: [
            'cardBackgroundColor',
            'textColor',
            'lightTextColor',
            'cardBorder'
        ],
        pixel: [
            'cardRadius'
        ],
        number: [
            'nbOfLines'
        ],
        font: [
            'cardFont'
        ]
    },
    Popup: {
        color: [
            'popupBackgroundColor',
            'crossColor',
            'svgColor',
            'buttonBorderColor',
            'buttonBackgroundColor',
            'buttonTextColor',
            'titlesColor',
            'descriptionColor'

        ],
        font: [
            'popupDescriptionFont',
            'popupTitlesFont'
        ]
    }
}
const PersoBar = ({ opt, setOpt }) => {
    return (
        <>
            {Object.entries(Params).map(([title, subCat]) => {
                return (
                    <div className="perso-block" key={title}>
                        <h2>{title}</h2>
                        {Object.entries(subCat).map(([type, properties]) => {
                            let Input = () => <></>
                            switch (type) {
                                case "color": {
                                    Input = ColorInput
                                    break;
                                }
                                case "pixel": {
                                    Input = ({ value, onChange }) => <input type="number" value={parseFloat(value)} onChange={({ target: { value } }) => onChange((value || 0) + 'px')} />
                                    break;
                                }
                                case "number": {
                                    Input = ({ value, onChange }) => <input type="number" value={value} onChange={({ target: { value } }) => onChange(value)} />
                                    break;
                                }
                                case 'font': {
                                    Input = ({ value, onChange }) => <TimedChangeInput value={value} onTimedChange={onChange} ms={1200} placeholder="Enter font name" />
                                    break;
                                }
                                default: break;
                            }
                            return <Fragment key={type}>{properties.map(a => <Fragment key={a}><h4>{a[0].toUpperCase() + a.slice(1).replace(/([A-Z][a-z]*)/g, ` $1`)}</h4><Input value={opt.style?.[a]} onChange={v => setOpt({ style: { [a]: v } })} /></Fragment>)}</Fragment>
                        })}
                    </div>
                )
            })}
        </>
    )
}

export default PersoBar
