import React from 'react'
import './index.scss'
import TimedChangeInput from '../TimedChangeInput'
const ColorInput = ({ value: val, onChange, placeholder, ms = 1000 }) => {
    const openPicker = () => {
        const input = document.createElement('input')
        input.type = 'color'
        input.onchange = ({ target: { value } }) => onChange(value)
        input.click()
    }
    const formatted = val ? (val[0] !== "#" ? '#' : '') + val : ''
    const stringified = val?.[0] === "#" ? val.slice(1) : val

    return (
        <div className="color-input">
            <div onClick={openPicker} style={{ backgroundColor: formatted }} />
            <TimedChangeInput onTimedChange={onChange} ms={ms} value={stringified} placeholder={placeholder || "FFFFFF"} />
        </div>
    )
}

export default ColorInput
